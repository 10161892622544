import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoIosCart } from "react-icons/io";


export default function AppCartHeader({url}) {

  return (
    <header className='App-Cart-Header' id="App-Cart-Header">
      <Container style={{textAlign: "right"}}>
        <IoIosCart  className='Cart-Icon'/>
      </Container>
    </header>
  );
}