import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobileNav from '../Components/AppMobileNav';

export default function NotFound({url, page}) {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
       <AppHeader page={"not-found"} url={url} />
       <AppMobileNav page={page} url={url} />
       <main className='App-Page'>
        <h2>NotFound</h2>
       </main>
   </div>
  );
}