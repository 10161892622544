import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TiThMenu } from "react-icons/ti";

export default function AppMobileNav({page, url}) {

  return (
    <nav className='App-Mobile-Nav' id="Mobile-Nav">
      <ul>
        <li><a href={url + "/about-me"}>About Me</a></li>
        <li><a href={url + "/books"}>Book Store</a></li>
        <li><a href={url + "/contact-me"}>Contact Me</a></li>
      </ul>
    </nav>
  );
}