import React from 'react';
import AppHeader from '../Components/AppHeader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AppCartHeader from '../Components/AppCartHeader';
import AppMobileNav from '../Components/AppMobileNav';

export default function Home({url, page}) {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
      <AppCartHeader />
      <AppHeader page={page} url={url} />
      <AppMobileNav page={page} url={url} />
   </div>
  );
}