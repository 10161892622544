import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home from "../../Routes/Home"
import Books from "../../Routes/Books"
import NotFound from "../../Routes/NotFound"
import AboutMe from "../../Routes/AboutMe"
import ContactMe from "../../Routes/ContactMe"

import "../Style/App-Main.css"
import "../Style/App-Responsive.css"

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  var url = "https://lucascapelle.com"

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={
           <Home url={url} page={"home"} />
        } />

<Route exact path="/books" element={
           <Books url={url} page={"books"}  />
        } />

        <Route exact path="/about-me" element={
           <AboutMe url={url} page={"AboutMe"}  />
        } />

<Route exact path="/contact-me" element={
           <ContactMe url={url} page={"AboutMe"}  />
        } />

         <Route exact path="*" element={
           <NotFound url={url} page={"not-found"}  />
        } />
      </Routes>
    </div>
  );
}

export default App;
