import React from 'react';
import AppHeader from '../Components/AppHeader';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AppMobileNav from '../Components/AppMobileNav';

export default function ContactMe({url, page}) {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
       <AppHeader page={page} url={url} />
       <AppMobileNav page={page} url={url} />
       <main className='App-Page'>
            <section className='App-Section Page-Header-Section'>
                <Container>
                        <h2>Contact Lucas Capelle</h2>
                </Container>
            </section>
            <section className='App-Section Content-Section'>
              <Container>
                <Form>
                  <h2>Contact Form</h2> <br />
                  <Form.Group className="mb-3" controlId="formFullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Full Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Container>
            </section>
       </main>
   </div>
  );
}