import React from 'react';
import AppHeader from '../Components/AppHeader';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import AppMobileNav from '../Components/AppMobileNav';

export default function Home({url, page}) {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
       <AppHeader page={page} url={url} />
       <AppMobileNav page={page} url={url} />
       <main className='App-Page'>
            <section className='App-Section Welcome-Section'>
                <Container>
                        <h2>Welcome</h2>
                        <p>Lucas Capelle is a fourteen-year-old boy who lives in Mamaroneck, NY. He started writing when he was twelve with the guidance of his mentor, EJ Flynn. In addition to writing the next novel in the 'Pellace' series, Lucas is pursuing projects in acting, filmmaking, and writing music.</p>
                        <img className = "Section-Image" src={"/lucas.jpg"} /> <br />
                        <Button variant="outline-light" onClick={() => window.location.href=url+ "/books"}>Browse Books</Button>
                </Container>
            </section>
       </main>
   </div>
  );
}