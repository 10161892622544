import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TiThMenu } from "react-icons/ti";

export default function AppHeader({page, url}) {

  function toggleMenu(){
    if ((document).getElementById("Mobile-Nav").classList.contains('Show-Nav')) {
      (document).getElementById("Mobile-Nav").classList.remove('Show-Nav')
    }else{
      (document).getElementById("Mobile-Nav").classList.add('Show-Nav')
    }
  }

  return (
    <header className={page == "books" ? ('App-Header Page-Books'):("App-Header")} id="App-Header">
      <Container>
        <Row>
          <Col md={""}>
            <div className='Header-Title'><a href={url}>Lucas Capelle</a></div>
          </Col>
          <Col md={1} className="Col-Mobile-Navigation">
            <div onClick={() => toggleMenu()}><TiThMenu /> Menu</div>
          </Col>
          <Col className="Col-Navigation" md={""}>
            <nav className='App-Desktop-Navigation'>
              <ul>
                <li><a href={url + "/about-me"}>About Me</a></li>
                <li><a href={url + "/books"}>Book Store</a></li>
                <li><a href={url + "/contact-me"}>Contact Me</a></li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
}